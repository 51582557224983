<template lang="pug">
  .reaction(
    v-click-outside='handleClickOutside'
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
    ref="reaction"
  )
    .reaction__current(
      @touchstart.prevent.stop="touchStart"
      @touchend.prevent.stop="touchEnd"
      @click="() => toggleReaction()"
    )
      .reaction__current-icon(
        :class="classCurrentReaction"
        @animationend="onAnimationEnd"
      )
      span.reaction__current-count(
        v-show="reactionsCount"
      ) {{ reactionsCount }}
    .reaction__menu(
      v-if="isOpenMenu"
    )
      .reaction__menu-item(
        v-for='reaction of reactions'
        :key='reaction.type'
        :class="getReactionItemClass(reaction)"
        @click.stop="toggleReaction(reaction)"
      )
        .reaction__menu-icon(
          :class="getReactionIconClass(reaction)"
        )
        .reaction__menu-count(
          v-show="isShowCount"
        ) {{ getReactionCount(reaction) }}
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import { vOnClickOutside } from '@vueuse/components';
  import { useReactionsStore, type ReactionObserverItem } from '~/store/reactions';
  import { checkAccessTokenAndDoAction } from '~/utils/checkAccess';
  import { mapActions } from 'pinia';
  import { isHealthPath } from '~/utils/router';

  export default defineNuxtComponent({
    name: 'Reaction',

    directives: {
      clickOutside: vOnClickOutside,
    },

    props: {
      articleId: {
        type: Number as PropType<number>,
        required: true,
      },
    },

    data: () => ({
      mouseLeaveTimeoutId: null as null | NodeJS.Timeout,
      isOpenMenu: false,
      isAnimateReactionCurrent: false,
      isUserChangeReaction: false,
      isLongTap: false,
      longTapTimeout: null as null | NodeJS.Timeout,
      longTapDelay: 500,
      observerId: (((1 + Math.random()) * 0x10000) | 0).toString(12) + 'observer',
      mouseTimeoutId: undefined as number | undefined,
    }),
    computed: {
      observerItem(): ReactionObserverItem | null {
        return useReactionsStore().getObservers(this.observerId);
      },
      isShowHeartbeat(): boolean {
        return this.observerItem ? this.observerItem.isShowHeartbeat : false;
      },
      isBeforeLicked(): boolean {
        return this.observerItem ? this.observerItem.isBeforeLicked : true;
      },
      isShowCount(): boolean {
        return this.reactions.some((reaction: IReaction) => reaction.count);
      },

      getReactions(): Function {
        return useReactionsStore().getReactions;
      },

      reactionCurrentType(): TReactionType | null {
        return useReactionsStore().getReactionsState(this.articleId);
      },

      reactionCurrent(): IReaction | null {
        const [first] = this.reactions.filter(
          (reaction: IReaction) => reaction.type === this.reactionCurrentType,
        );

        return first ?? null;
      },

      reactions(): IReaction[] {
        const reactions = this.getReactions(this.articleId);
        return reactions;
      },

      reactionsCount(): string {
        const count: number = this.reactions.reduce((result: number, reaction: IReaction) => {
          result += reaction.count;
          return result;
        }, 0);

        if (!count) {
          return '';
        }

        return count >= 1000 ? '1к+' : count.toString();
      },
      isAnimationHeartbeatClass(): boolean {
        return this.isShowHeartbeat && !this.isBeforeLicked && this.reactionCurrentType == null;
      },
      classCurrentReaction(): Record<string, boolean> {
        return {
          [`reaction__current-icon--${this.reactionCurrentType || 'default'}`]: true,
          'reaction__current-icon--animation': this.isAnimateReactionCurrent,
          animation__heartbeat: this.isAnimationHeartbeatClass,
        };
      },

      isHealth(): boolean {
        return isHealthPath(useRoute().path);
      },
    },

    watch: {
      async reactionCurrentType(reaction: TReactionType): Promise<void> {
        this.setIsBeforeLicked(this.observerId, true);
        await this.$nextTick();
        this.isAnimateReactionCurrent = Boolean(reaction) && this.isUserChangeReaction;
      },
    },
    mounted() {
      this.addObserver();
    },

    methods: {
      ...mapActions(useReactionsStore, {
        updateReaction: 'updateReaction',
        setIsBeforeLicked: 'setIsBeforeLicked',
        setIsShowHeartbeat: 'setIsShowHeartbeat',
        createIntersectionObserver: 'createIntersectionObserver',
        disconnectIntersectionObserver: 'disconnectIntersectionObserver',
      }),

      addObserver(): void {
        const reactionObserverItem: ReactionObserverItem = {
          id: this.observerId,
          intersectionObserver: null,
          isShowHeartbeat: false,
          isBeforeLicked: true,
        };
        const callback = (entries: IntersectionObserverEntry[]) => {
          const entry = entries[0];
          if (entry.isIntersecting) {
            if (this.isBeforeLicked) {
              return;
            }
            this.setIsShowHeartbeat(this.observerId, entry.isIntersecting);
          } else {
            this.setIsShowHeartbeat(this.observerId, entry.isIntersecting);
            this.setIsBeforeLicked(this.observerId, false);
          }
        };
        const io = new IntersectionObserver(callback, {
          root: null,
          rootMargin: '-100px 0px',
          threshold: 1.0,
        });
        reactionObserverItem.intersectionObserver = io;
        this.createIntersectionObserver(reactionObserverItem);
        this.$nextTick(() => {
          io.observe(this.$refs.reaction as Element);
        });
      },
      removeObserver() {
        this.observerItem?.intersectionObserver?.unobserve(this.$refs.reaction as Element);
        this.setIsBeforeLicked(this.observerId, true);
        this.disconnectIntersectionObserver();
      },
      onAnimationEnd() {
        this.isAnimateReactionCurrent = false;
        this.isUserChangeReaction = false;
      },

      toggleReaction(reaction?: IReaction): void {
        this.removeObserver();
        this.isOpenMenu = false;
        this.isUserChangeReaction = true;
        checkAccessTokenAndDoAction(this, () => this.toggleReactionAction(reaction));
      },

      getReactionIconClass(reaction: IReaction): Record<string, boolean> {
        return {
          [`reaction__menu-icon--${reaction.icon}`]: Boolean(reaction.icon),
          'reaction__menu-icon--current': reaction.type === this.reactionCurrentType,
        };
      },

      getReactionItemClass(reaction: IReaction): Record<string, boolean> {
        return {
          'reaction__menu-item--current': reaction.type === this.reactionCurrentType,
        };
      },

      getReactionCount(reaction: IReaction): number {
        return reaction.count;
      },

      touchStart(): void {
        this.isLongTap = false;
        this.longTapTimeout = setTimeout(() => {
          this.isOpenMenu = true;
          this.isLongTap = true;
        }, this.longTapDelay);
      },

      touchEnd() {
        if (!this.isLongTap) {
          if (this.longTapTimeout) {
            clearTimeout(this.longTapTimeout);
          }
          this.toggleReaction();
        }
      },

      handleClickOutside(): void {
        this.isOpenMenu = false;
      },

      handleMouseEnter(): void {
        if (this.mouseLeaveTimeoutId) {
          clearTimeout(this.mouseLeaveTimeoutId);
        }
        this.isOpenMenu = true;
      },

      handleMouseLeave(): void {
        if (this.mouseLeaveTimeoutId) {
          clearTimeout(this.mouseLeaveTimeoutId);
        }
        this.mouseLeaveTimeoutId = setTimeout(() => {
          this.isOpenMenu = false;
        }, 100);
      },

      async toggleReactionAction(reaction?: IReaction) {
        await this.updateReaction(
          this.articleId,
          reaction?.type || this.reactionCurrentType || 'heart',
          this.isHealth,
        );

        this.$nextTick(() => this.trackToggleLike(reaction));
      },

      async trackToggleLike(reaction?: IReaction) {
        const item = reaction?.title || this.reactionCurrent?.title || 'Сердечко';

        this.$sendYandexMetrika({
          level1: 'Клик_Реакции',
          level4: '',
          level5:
            reaction?.type === this.reactionCurrentType || this.reactionCurrent
              ? 'Поставили реакцию'
              : 'Убрали реакцию',
          level6: item,
          level8: this.isLongTap ? 'Реакция из меню на мобилке' : 'Стандартная реакция',
        });

        const { trackSocialInteraction } = await import('@snowplow/browser-plugin-site-tracking');

        trackSocialInteraction({
          action:
            reaction?.type === this.reactionCurrentType || this.reactionCurrent ? 'like' : 'unlike',
          network: reaction?.type || this.reactionCurrent?.type || 'like',
          target: location.pathname,
        });
      },
    },
  });
</script>

<style lang="scss" scoped>
  .reaction {
    position: relative;

    &__current {
      padding: 4px 10px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      user-select: none;

      @include tablet {
        padding: 4px 12px;
      }

      &-count {
        @include fontSmallText;
        color: #969698;
        margin-left: 2px;
        font-weight: 700;
      }

      &-icon {
        width: 24px;
        height: 24px;

        &--animation {
          animation: zoom-in-zoom-out 1s ease 2;
        }

        &--default {
          @include heartStrokeIcon;
          background-size: 24px 24px;
        }

        &--heart {
          @include heartIcon;
          background-size: 24px 24px;
        }

        &--thumbs_up {
          @include thumbsUpIcon;
          background-size: 24px 24px;
        }

        &--on_fire {
          @include fireIcon;
          background-size: 24px 24px;
        }

        &--thumbs_down {
          @include thumbsDownIcon;
          background-size: 24px 24px;
        }

        &--angry {
          @include angryIcon;
          background-size: 24px 24px;
        }
      }
    }

    &__menu {
      display: flex;
      flex-wrap: nowrap;
      position: absolute;
      left: -8px;
      bottom: calc(100% + 4px);
      padding: 4px;
      background: #ffffff;
      border: 1px solid #e7e7e7;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      z-index: 1;

      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 6px;
        left: 0;
        top: 100%;
        background: transparent;
      }

      &-item {
        padding: 4px;
        border-radius: 2px;
        overflow: hidden;

        &--current {
          background-color: #f3f5fc;

          .reaction__menu-count {
            color: #000000;
          }
        }

        @include hover {
          background-color: var(--bg-color-substrate-2);

          .reaction__menu-count {
            color: #000000;
          }

          .reaction__menu-icon {
            transform: scale(1.3);
            transform-origin: center center;
          }
        }
      }

      &-icon {
        width: 40px;
        height: 40px;
        transition: transform 0.3s;
        will-change: transform;

        &--heart {
          @include heartIcon;
        }
        &--thumbs_up {
          @include thumbsUpIcon;
        }
        &--on_fire {
          @include fireIcon;
        }
        &--thumbs_down {
          @include thumbsDownIcon;
        }
        &--angry {
          @include angryIcon;
        }
      }

      &-count {
        @include fontTinyText;
        color: #969698;
        text-align: center;
        margin-top: 2px;
      }
    }

    @keyframes zoom-in-zoom-out {
      0% {
        transform: scale(1, 1);
      }
      50% {
        transform: scale(1.5, 1.5);
      }
      100% {
        transform: scale(1, 1);
      }
    }
    .animation__heartbeat {
      animation: heartbeat 600ms ease-in 0s 1 forwards normal;
    }

    @keyframes heartbeat {
      0% {
        transform: scale(1);
        animation-timing-function: ease-in;
      }
      50% {
        transform: scale(1.16);
        animation-timing-function: ease-out;
      }
      66.666666% {
        transform: scale(1);
        animation-timing-function: ease-in;
      }
      83.333333% {
        transform: scale(1.16);
        animation-timing-function: ease-out;
      }
      100% {
        transform: scale(1);
      }
    }
  }
</style>
