<template lang="pug">
.article-card(
  :data-id="article.id"
  :class="{ 'article-card--promo': isPromo }"
)
  .article-card__above-cover
    span( v-if="isPromo" class="article-card__date") {{ dateText }}

    ArticleCardHeader.article-card__meta(
      :article="article"
      @clicked-on-category="(link) => $emit('clicked-on-category', link)"
      @clicked-on-author="(link) => $emit('clicked-on-author', link)"
    )
    h1.article-card__title(
      v-html="title"
      :class="{'article-card__title--only': !article.excerpt}"
    )
    .article-card__subtitle(v-html="article.excerpt")
    AuthorsExperts(
      v-if="article.show_authors_experts"
      :author="article.author"
      :expert="article.expert"
    )
  .article-card__cover(
    v-if="article.img.length && !isErrorWhileLoadingCover"
  )
    AppPicture(
      :alt="imgAlt"
      :images="article.img"
      :defaultImages="article.defaultImg"
      :sizesSliced="sizesSliced"
      :sizesByDevice="sizesByDevice"
      loading="eager"
    )
  component.article-card__actions(
    :is="articleCardComponent"

    @favorite-delete="onFavoriteDeleteEmit"
    @favorite-create-folder-and-add="onFavoriteCreateFolderAndAddEmit"
    @favorite-create-folder-and-transfer="onFavoriteCreateFolderAndTransferEmit"
    @favorite-transfer-to-folder="onFavoriteTransferToFolderEmit"
    @favorite-add-to-folder="onFavoriteAddToFolderEmit"
    @favorite-deferred="onFavoriteDeferredEmit"

    gtmEventPosition="top"
    :articleId="article.id"
    :url="article.teaserUrl || article.url"
    :coverUrl="(article.img && article.img[0] && article.img[0].url) ? article.img[0].url : null"
    :commentsCount="commentsCount"
    :commentsEnabled="commentsEnabled && !article.teaserUrl"
    :title="title"
    :user="user"
    :favorite="favorite"
    :folders="folders"
    :canHaveReactions="article.canHaveReactions"
  )
</template>

<script lang="ts">
  import type { PropType } from 'vue';
  import { getDateText } from '@devhacker/shared/utils/date';
  import AppPicture from '@/components/lh-ui/AppPicture/AppPicture.vue';

  import ArticleCardActionsWithReactions from '~/components/ArticleCardActionsWithReactions/ArticleCardActionsWithReactions.vue';
  import ArticleCardActions from '~/components/ArticleCardActions/ArticleCardActions.vue';
  import TheLink from '~/components/TheLink.vue';
  import AuthorsExperts from '~/components/AuthorsExperts/AuthorsExperts.vue';
  import ArticleCardHeader from '~/components/ArticleCard/ArticleCardHeader.vue';

  import { isAnyRecipePage } from '~/utils/router';

  export default defineNuxtComponent({
    name: 'ArticleCard',
    components: {
      ArticleCardHeader,
      TheLink,
      ArticleCardActions,
      ArticleCardActionsWithReactions,
      AuthorsExperts,
      AppPicture,
    },
    provide() {
      return {
        eventPositionShareBlock: () => 'вверху статьи',
      };
    },

    props: {
      article: {
        type: Object as PropType<IArticle>,
        required: true,
      },
      commentsCount: {
        required: true,
        type: Number as PropType<number>,
      },
      user: {
        type: Object as PropType<IUser | null>,
        default: null,
      },
      favorite: {
        type: Object as PropType<IFavoriteItem | null>,
        default: null,
      },
      folders: {
        required: true,
        type: Object as PropType<IFolders>,
      },
      commentsEnabled: {
        type: Boolean as PropType<boolean>,
        required: true,
      },
      title: {
        type: String as PropType<string>,
        required: true,
      },
    },
    data() {
      return {
        isErrorWhileLoadingCover: false as boolean,
      };
    },

    computed: {
      isPromo(): boolean {
        return this.article.isPromo;
      },
      imgAlt(): string {
        return this.article.image?.alt || this.title;
      },
      dateText(): string {
        return getDateText(this.article.date);
      },
      isAnyRecipePage(): boolean {
        const route = useRoute();
        return isAnyRecipePage(route.name);
      },
      articleCardComponent(): string {
        return this.isAnyRecipePage ? 'ArticleCardActions' : 'ArticleCardActionsWithReactions';
      },
      computedAuthorText(): string {
        const { article } = this;
        // если партнерский пост, не показывать имя автора
        return article.isAdvertising ? '' : article.author.name;
      },

      sizesSliced(): number[] {
        return this.isPromo
          ? [390, 640, 732, 900, 1350, 1800, 2250]
          : [390, 640, 778, 1170, 1500, 1945];
      },

      sizesByDevice(): any {
        return this.isPromo
          ? { mobile: '100vw', tablet: '732px', bigTablet: '900px' }
          : { mobile: '100vw', tablet: '592px', bigTablet: '638px', desktop: '778px' };
      },
    },

    methods: {
      getDateText,

      onCoverLoadError(): void {
        if (!this.isErrorWhileLoadingCover) {
          this.isErrorWhileLoadingCover = true;
        }
      },
      onFavoriteDeferredEmit(deferredAction: Function, ctx: any) {
        this.$emit('favorite-deferred', deferredAction, ctx);
      },
      onFavoriteAddToFolderEmit(folder: IFolderItem) {
        this.$emit('favorite-add-to-folder', folder);
      },
      onFavoriteTransferToFolderEmit(favorite: IFavoriteItem, folder: IFolderItem) {
        this.$emit('favorite-transfer-to-folder', favorite, folder);
      },
      onFavoriteCreateFolderAndTransferEmit(nameFolder: string, favorite: IFavoriteItem): void {
        this.$emit('favorite-create-folder-and-transfer', nameFolder, favorite);
      },
      onFavoriteCreateFolderAndAddEmit(nameFolder: string): void {
        this.$emit('favorite-create-folder-and-add', nameFolder);
      },
      onFavoriteDeleteEmit(favorite: IFavoriteItem): void {
        this.$emit('favorite-delete', favorite);
      },
    },
  });
</script>

<style lang="scss">
  $orange: #ed5e42;
  $gray: #5c5c5c;
  $hoverColor: #c5c8ce;
  $borderColor: #e7e7e7;

  .article-card {
    position: relative;

    display: flex;
    flex-direction: column;

    margin: 0 0 32px 0;
    max-width: 100vw;
    background: white;
    padding-top: 16px;

    @include tablet {
      max-width: 642px;
      margin-bottom: 40px;
      &--promo {
        padding-top: 16px;
      }
    }

    @include big-tablet {
      max-width: 782px;
    }

    &__date {
      color: #5c5c5c;
      font-family: $mainFont;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-decoration: none;
      display: block;
      margin-bottom: 4px;

      @include tablet {
        margin-bottom: 8px;
      }

      &:not(span) {
        @include hover {
          color: #000000;
        }
      }
    }

    &__title {
      padding-bottom: 8px;
      text-decoration: none;
      overflow-wrap: break-word;

      @include fontH1;

      &--only {
        margin-bottom: 0px;
      }

      &--only {
        padding-bottom: 4px;
      }

      @include tablet {
        padding-bottom: 12px;

        &--only {
          padding-bottom: 4px;
        }
      }
    }

    &__cover {
      text-decoration: none;
      color: transparent;
      line-height: 0;
      display: block;
      margin: 16px auto 0;
      background: url(placeholder.svg) center top / cover no-repeat;
      width: 100%;
    }

    &__above-cover {
      padding: 0 16px;

      @include tablet {
        padding: 0;
      }

      @include big-tablet {
        padding: 0 24px;
      }
    }

    &__subtitle {
      overflow: hidden;
      @include fontNormalText;

      &:empty {
        display: none;
      }

      a {
        @include linkStyle;
      }
    }

    &__meta {
      margin-bottom: 4px;

      @include tablet {
        margin-bottom: 8px;
      }
    }

    &__admin-panel {
      position: relative;
      margin-top: 16px;
      z-index: 3;
    }

    &__actions {
      margin: 0 16px;
      padding: 16px 0;
      border-bottom: 1px solid $borderColor;

      @include tablet {
        margin: 0;
      }

      @include big-tablet {
        margin: 0px 24px;
      }
    }

    .article-card__cover {
      .app-picture__img {
        object-fit: fill;
        height: auto;
      }
    }
  }
</style>
