<template>
  <div class="article-card-actions">
    <div
      v-if="canHaveReactions"
      class="article-card-actions__action article-card-actions__action--reaction"
    >
      <Reaction :article-id="articleId" />
    </div>
    <a
      v-if="commentsEnabled && !isCommentsServiceError"
      class="article-card-actions__action article-card-actions__comments-count"
      :href="getRelativeLink(url) + '#comments'"
      :target="openCommentsInNewTab ? '_blank' : null"
      @click="onClickCommentIcon"
    >
      <SvgAsset :content="commentIconContent" />
      <client-only>
        <span v-if="isHasComments">{{ commentsCount }}</span>
      </client-only>
    </a>
    <div class="article-card-actions__action article-card-actions__favorite">
      <FavoriteBlock
        :user="user"
        :favorite="favorite"
        :folders="folders"
        :is-show-favorite-folder-name="false"
        :favorite-menu-offsets="favoriteMenuOffsets"
        :color="'#969698'"
        :hover-color="'#969698'"
        :color-text="'#5c5c5c'"
        :color-text-hover="'#000000'"
        @favorite-delete="onFavoriteDeleteEmit"
        @favorite-create-folder-and-add="onFavoriteCreateFolderAndAddEmit"
        @favorite-create-folder-and-transfer="onFavoriteCreateFolderAndTransferEmit"
        @favorite-transfer-to-folder="onFavoriteTransferToFolderEmit"
        @favorite-add-to-folder="onFavoriteAddToFolderEmit"
        @favorite-deferred="onFavoriteDeferredEmit"
        @open-favorite-menu="upZIndexRecipeLayoutContent"
        @close-favorite-menu="downZIndexRecipeLayoutContent"
      />
    </div>

    <ShareButton
      class="article-card-actions__action"
      :share-img-url="coverUrl"
      :share-title="title"
      :share-link="url"
    />
  </div>
</template>

<script lang="ts">
  import type { PropType, PropOptions } from 'vue';
  import { isMobileOnly } from 'mobile-device-detect';
  import FavoriteBlock from '@/components/lh-ui/FavoriteBlock/FavoriteBlock.vue';
  import Reaction from '~/components/Reaction.vue';
  import { isHomeLink, getRelativeLink, getFullUrl } from '~/utils';
  import ShareButton from '~/components/ShareButton.vue';
  import SvgAsset from '@devhacker/shared/components/SvgAsset.vue';
  import { useServiceErrorsStore } from '~/store/errors';
  import commentIconContent from '~/assets/images/comment-icon.svg?raw';

  export default defineNuxtComponent({
    name: 'ArticleCardActionsWithReactions',

    components: {
      FavoriteBlock,
      Reaction,
      ShareButton,
      SvgAsset,
    },

    props: {
      gtmEventPosition: {
        type: String as PropType<string>,
        default: 'top',
      },
      articleId: {
        type: Number as PropType<number>,
        required: true,
      },
      canHaveReactions: {
        type: Boolean as PropType<boolean>,
        default: true,
      },
      url: {
        type: String as PropType<string>,
        required: true,
      },
      coverUrl: {
        type: String as PropType<string | null>,
        default: null,
      },
      commentsCount: {
        required: true,
        type: Number as PropType<number>,
      },
      commentsEnabled: {
        type: Boolean,
        default: true,
      } as PropOptions<boolean>,
      title: {
        type: String,
        required: true,
      } as PropOptions<string>,
      user: {
        type: Object as PropType<IUser | null>,
        default: null,
      },
      favorite: {
        required: false,
        type: Object as PropType<IFavoriteItem | null>,
        default: null,
      },
      folders: {
        required: true,
        type: Object as PropType<IFolders>,
      },
      scrollToComments: {
        type: Boolean as PropType<boolean>,
        default: true,
      },
      openCommentsInNewTab: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
      type: {
        type: String as PropType<'article' | 'recipe'>,
        default: 'article',
      },
    },
    data() {
      return {
        favoriteMenuOffsets: {
          top: 8,
          left: -8,
        } as TOffsets,
      };
    },
    computed: {
      commentIconContent() {
        return commentIconContent;
      },
      isCommentsServiceError() {
        return useServiceErrorsStore().articleCommentsServiceError;
      },
      isHasComments(): boolean {
        return this.commentsCount > 0;
      },
    },
    methods: {
      isHomeLink,
      getRelativeLink,

      // TODO
      // костыль - повышаем zIndex чтобы перекрыть кнопку фильтра #1phmbzy
      // при открытии меню добавления в избранное
      upZIndexRecipeLayoutContent() {
        if (isMobileOnly) {
          const topHeaderContainer = document.getElementById('recipes-layout-content');
          if (topHeaderContainer) {
            topHeaderContainer.classList.add('recipes-layout__content--up');
          }
        }
      },

      // TODO
      // костыль - понижаем zIndex чтобы перекрыть кнопку фильтра #1phmbzy
      // пр изакрытии меню добавления в избранное
      downZIndexRecipeLayoutContent() {
        if (isMobileOnly) {
          const topHeaderContainer = document.getElementById('recipes-layout-content');
          if (topHeaderContainer) {
            topHeaderContainer.classList.remove('recipes-layout__content--up');
          }
        }
      },

      onClickCommentIcon(event: Event): void {
        const eventElements: Record<string, string> = {
          top: 'внутренние_верхняя иконка комментариев',
          bottom: 'внутренние_нижняя иконка комментариев',
          archives: 'Архивы_иконка комментариев',
          'recipe-top': 'внутренние_верхняя иконка комментариев',
          'recipe-filters': 'Архивы_иконка комментариев',
          'recipe-main': 'Главная_иконка комментариев',
        };
        const element = eventElements[this.gtmEventPosition];

        this.$sendYandexMetrika({
          level1: 'Комментарии_переход к комментариям',
          level4: getFullUrl(this.url),
          level6: element,
        });

        if (this.scrollToComments) {
          event.preventDefault();
          const comments: HTMLDivElement | null = document.querySelector('#comments');
          if (comments) {
            comments.scrollIntoView();
          }
        }
        this.$emit('to-comments');
      },
      onFavoriteDeferredEmit(deferredAction: Function, ctx: any) {
        this.$emit('favorite-deferred', deferredAction, ctx);
      },
      onFavoriteAddToFolderEmit(folder: IFolderItem) {
        this.$emit('favorite-add-to-folder', folder);
      },
      onFavoriteTransferToFolderEmit(favorite: IFavoriteItem, folder: IFolderItem) {
        this.$emit('favorite-transfer-to-folder', favorite, folder);
      },
      onFavoriteCreateFolderAndTransferEmit(nameFolder: string, favorite: IFavoriteItem): void {
        this.$emit('favorite-create-folder-and-transfer', nameFolder, favorite);
      },
      onFavoriteCreateFolderAndAddEmit(nameFolder: string): void {
        this.$emit('favorite-create-folder-and-add', nameFolder);
      },
      onFavoriteDeleteEmit(favorite: IFavoriteItem): void {
        this.$emit('favorite-delete', favorite);
      },
    },
  });
</script>

<style lang="scss" scoped>
  .article-card-actions {
    display: flex;
    align-items: center;

    &__action {
      display: flex;
      padding: 4px 10px;
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      min-width: 0;
      background-color: #f5f5f4;
      border-radius: 40px;
      transition: background-color 0.3s;
      @include tablet {
        padding: 4px 12px;
      }

      &--reaction {
        padding: 0;
      }

      :deep(.svg-asset) {
        height: 24px;
        width: 24px;
        svg {
          height: 24px;
          width: 24px;
          stroke: #969698;
          stroke-width: 2px;
          fill: none;
        }
      }

      @include hover {
        background-color: var(--bg-color-substrate-2);
      }

      span {
        @include fontSmallText;
        color: #969698;
        margin-left: 2px;
        font-weight: 700;
      }

      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    &__favorite {
      overflow: hidden;

      .lh-button-icon__text {
        color: #5c5c5c;
      }

      @include tablet {
        overflow: inherit;
      }
    }

    &__comments-count {
      flex-shrink: 0;
    }
  }

  // https://youtrack.lifehacker.ru/issue/LH-1128
  .article-note_empty + .article__actions {
    margin-top: 16px;
    @include desktop {
      margin-top: 0;
    }
  }
</style>
